<template>
  <v-container class="my-15">
    <v-main v-if="userChapters.length == 0" class="text-center">
      <p class="text-h5 mt-10">Aún no tienes capítulos disponibles</p>
      <v-btn rounded color="cyan darken-2" small dark depressed to="/">Ir al catálogo</v-btn>
    </v-main>
    <v-main v-else>
      <p
        class="text-h4 mt-15 text-center font-weight-medium cyan--text text--darken-2"
      >
        MIS CAPÍTULOS
      </p>
      <div v-for="(chap, index) in sortedByPlay" :key="index" class="my-10">
        <v-subheader class="px-5 text-uppercase font-weight-bold text-body-2 text-md-body-1">{{ index }}</v-subheader>
        <v-divider></v-divider>
        <v-card color="transparent" class="pt-4 px-5" flat v-for="c in chap" :key="c.id">
          <p v-if="c.description" class="pa-0 mb-4 text-body-2 text-justify">{{c.description}}</p>
          <v-row>
            <!-- Chapter details -->
            <v-col cols="12" md="9">
              <v-card-text class="pa-0 text-justify">
                <!-- Chapter number -->
                <p class="pa-0 mb-0 text-caption text-md-body-2 font-weight-bold text-uppercase cyan--text text--darken-2">
                  Capítulo {{ c.chapter }}
                </p>
                <!-- Chapter name -->
                <p class="pa-0 mb-0 text-body-1 text-md-h6 font-weight-bold">
                  {{ c.title }}
                </p>
                <!-- Chapter summary -->
                <p class="pa-0 mb-0 text-body-2">{{ c.summary }}</p>
              </v-card-text>
            </v-col>
            <!-- Play button -->
            <v-col cols="12" md="3" class="mt-auto">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  small
                  text
                  color="cyan darken-2"
                  @click="playChapter(c)"
                >
                  <span class="d-md-none mr-1">Reproducir</span>
                  <v-icon>mdi-play-circle</v-icon>
                </v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-main>
    <audio-player
      v-if="showPlayer"
      @close-player="closePlayer"
      :currentChapter="currentChapter"
      :showPlayer="showPlayer"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

// Helper function used to sort chapters
function compare(a, b) {
  if (a.chapter < b.chapter) return -1;
  if (a.chapter > b.chapter) return 1;
  return 0;
}

export default {
  components: {
    AudioPlayer: () => import("../components/AudioPlayer"),
  },
  data() {
    return {
      showPlayer: false,
      plays: [],
      currentChapter: {},
    };
  },
  computed: {
    ...mapState(["userPlays", "userChapters"]),
    ...mapGetters(["currentUser"]),
    sortedChapters() {
      if (this.userChapters) {
        const chapters = this.userChapters;
        return chapters.sort(compare);
      } else {
        return null;
      }
    },
    sortedByPlay(){
      let chapter = this.sortedChapters
      return chapter.reduce(function (r, a) {
        r[a.play] = r[a.play] || [];
        r[a.play].push(a);
        return r;
      }, Object.create(null));
    }
  },
  methods: {
    playChapter(chapter) {
      this.showPlayer = true;
      this.currentChapter = chapter;
    },
    closePlayer() {
      this.showPlayer = false;
      this.currentChapter = {};
    },
    ...mapActions(["getUserChapters"]),
  },
  created() {
    this.getUserChapters(this.currentUser.uid);
  },
};
</script>
